import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import createUniqueStore from './store';

window.RKTFLUXPROMORENDER = (domId, props) => {
    ReactDOM.render(
        <Provider store={createUniqueStore()} >
          {React.createElement(App, props)}
        </Provider>
        , document.getElementById(domId));
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();