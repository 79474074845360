import React from 'react';

const CloseSvg = ({onClick}) => (
  <span className="pointer o-80 relative w3" onClick={onClick}>
    <div className="absolute backToResult">
      <svg viewBox="0 0 24 24" width="24" height="24" fill="#828282" stroke="none" strokeWidth="1"><g><title id="title">icons / nav24 / close</title></g><g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="icons-/-nav24-/-close" fill="#828282"><g id="Icons-/-close-white" transform="translate(4.000000, 4.000000)"><path d="M13.6824394,0.281232507 C14.0557458,-0.0920738405 14.6588376,-0.0942312121 15.0307839,0.27771506 L15.7222849,0.969216131 C16.0936487,1.34057985 16.0899822,1.94634589 15.7187675,2.31756055 L10.036328,8 L15.7187675,13.6824394 C16.0920738,14.0557458 16.0942312,14.6588376 15.7222849,15.0307839 L15.0307839,15.7222849 C14.6594202,16.0936487 14.0536541,16.0899822 13.6824394,15.7187675 L8,10.036328 L2.31756055,15.7187675 C1.94425421,16.0920738 1.3411624,16.0942312 0.969216131,15.7222849 L0.27771506,15.0307839 C-0.0936486555,14.6594202 -0.0899821601,14.0536541 0.281232507,13.6824394 L5.96367195,8 L0.281232507,2.31756055 C-0.0920738405,1.94425421 -0.0942312121,1.3411624 0.27771506,0.969216131 L0.969216131,0.27771506 C1.34057985,-0.0936486555 1.94634589,-0.0899821601 2.31756055,0.281232507 L8,5.96367195 L13.6824394,0.281232507 Z" id="Combined-Shape"></path></g></g></g>
      </svg>
    </div>
  </span>
);

export default CloseSvg;
